import React, { Component } from "react";
import { connect } from "react-redux";

import Aux1 from "../Aux1/Aux1";

//import classes from "./Layout.css";

// import '../../styles/css/bootstrap-datetimepicker.min.css';
import "../../styles/css/fontawesome-all.min.css";
import "../../styles/css/fonts.css";
import "../../styles/css/jquery.mCustomScrollbar.min.css";

import "../../styles/css/customScroll.css";
// import "react-datepicker/dist/react-datepicker.css";
import "../../styles/css/tooltip.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../styles/css/utility.css";
import "../../styles/css/style.css";
// import "react-grid-layout/css/styles.css";
// import "react-resizable/css/styles.css";
import axios from "../../axios-api";

import "../../styles/css/responsive.css";

//import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
//import LeftNavigation from "../../components/Navigation/LeftNavigation/LeftNavigation";
//import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
import TopHeader from "../../components/Navigation/TopHeader/TopHeader";
import Login from "../../components/Launch/Login";
//import Footer from '../../components/footer/footer';
//import PopUp from '../../components/PopUp/PopUp';

const urlArray = ["/", "/login"];
class Layout extends Component {
  state = {
    showSideDrawer: false,
    rawhtml: "",
  };

  componentDidMount() {
   if(localStorage.getItem("printid") !== null && localStorage.getItem("printid") !== "")
   {
    axios
      .get("Users/PrintTicket/"+localStorage.getItem("printid"))
      .then((res) => {
        //  rawhtml = res.data;
        this.setState({ rawhtml: res.data });
        //  return(<div dangerouslySetInnerHTML={{__html: rawhtml}} />);
      })
    }

  }
  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    this.setState((prevState) => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  render() {
    if (this.state.rawhtml !== "") {
      return <div dangerouslySetInnerHTML={{ __html: this.state.rawhtml }} />;
    } else if (this.props.isAuthenticated) {
      return (
        <Aux1>
          <TopHeader />
          {this.props.children}
          {/* <Footer />
          <Bottomnav /> */}
        </Aux1>
      );
    } else if (
      urlArray.find(
        (x) => x === this.props.children._owner.pendingProps.location.pathname
      ) === undefined &&
      (localStorage.token === undefined || localStorage.token === "")
    ) {
      localStorage.setItem(
        "templink",
        this.props.children._owner.pendingProps.location.pathname.replace(
          "#",
          ""
        )
      );
      return (
        <Aux1>
          <Login></Login>
        </Aux1>
      );
    } else {
      return (
        <Aux1>
          {/* <Header /> */}
          {this.props.children}
          {/* <Footer /> */}
        </Aux1>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(Layout);
