import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
// import CustomScroll from "react-custom-scroll";
// import PopUp from "../../PopUp/PopUp";
// import axios from "../../../axios-api";
// import { toast } from "react-toastify";
// import ReactToPrint from "react-to-print";
// import Tippy, { tippy } from "@tippy.js/react";
// import Aux1 from "../../../hoc/Aux1/Aux1";
import LeftNavigation from "../LeftNavigation/LeftNavigation";
//import "../../PopUp/PopUpPrint.css";
import "./TopHeader.css";

//let Userimg = require("../../../styles/img/user.png");
const logo = require("../../../styles/img/logo.png");
// const bellicon = require("../../../styles/img/bellicon.png");
// const userimage = require("../../../styles/img/user.png");
// const chatIcon = require("../../../styles/img/chatIcon.png");
// const reportIcon = require("../../../styles/img/reportIcon.png");

class topheader extends Component {
  state = {
    RedirectPath: "",
    Tab: "",
  };

  componentDidMount() {}

  render() {
    if (this.state.RedirectPath !== "") {
      return (
        <Redirect
          to={{
            pathname: this.state.RedirectPath,
            state: {
              RoomPage: this.state.SelectedBookingID,
              Tab: this.state.Tab,
            },
          }}
        />
      );
    }
    return (
      <>
        <header>
          <nav className="topbar flex-row flex-nowrap navbar navbar-dark fixed-top mainHeader">
            <NavLink className="navbar-brand" to={localStorage.getItem("link")}>
              {/* <img alt="" src={logo} className="img-fluid" /> */}
              <b>Dongam Mazar</b>
            </NavLink>
            {/* <div className="bellNotification ml-auto">
              <ul className="navbar-nav ml-auto align-items-center">
                <li className="nav-item dropdown notificationLink">
                  <a
                    className="nav-link "
                    style={{ cursor: "pointer" }}
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src={bellicon}
                      alt="notification"
                      className="img-fluid"
                    />
                    <span className="badge badge-primary">1</span>
                  </a>
                  <ul className="dropdown-menu shadow animated-growing notificationDropdown">
                    <li className="notificationHead text-light bg-dark">
                      <div className="row">
                        <div className="col-lg-12 col-sm-12 col-12">
                          <span>Notifications (1)</span>
                        </div>
                      </div>
                    </li>
                    <li className="notification-box">
                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-3 text-center">
                          <img
                            src={userimage}
                            className="w-50 rounded-circle"
                          />
                        </div>
                        <div className="col-lg-8 col-sm-8 col-8">
                          <strong className="text-info">Husain Ezzy</strong>
                          <div>Access Request pending for aprroval</div>
                          <small className="text-warning">
                            27.11.2015, 15:00
                          </small>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
             */}
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </button>
            <div
              className="collapse navbar-collapse "
              id="navbarSupportedContent"
            >
              <LeftNavigation isAuth={true} />
            </div>
          </nav>
        </header>
      </>
    );
  }
}
export default topheader;
