import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class Login extends Component {

    componentDidMount() {
        window.location.replace('https://mazaraat.org/#/Dongaon');//uncomment on prod
        // window.location.replace("https://alraihaan.babjisoftech.com/#/");
        // window.location.replace("https://alraihaan.mazaraat.org/#/"); //remove from prod
    }
   
    render() {
        return <></>;
                
    }
}

export default Login;